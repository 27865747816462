var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'},[_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"title black--text mb-2"},[_vm._v("My Account")]),_c('div',{staticClass:"mb-4"},[_vm._v("Account")]),_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","autocomplete":"off","outlined":"","dense":"","error-messages":errors,"color":"#F05326"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email address","autocomplete":"off","outlined":"","dense":"","readonly":"","error-messages":errors,"color":"#F05326"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])}),_c('div',{staticClass:"mb-4"},[_vm._v("Security")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.password),expression:"password"}]},[_c('ValidationProvider',{attrs:{"name":"Current Password","rules":"min:4|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 mb-1",attrs:{"autocomplete":"off","outlined":"","dense":"","color":"#F05326","label":"Current Password","type":_vm.passwordShow.showOld ? 'text' : 'password',"append-icon":_vm.passwordShow.showOld ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"required":""},on:{"click:append":function($event){_vm.passwordShow.showOld = !_vm.passwordShow.showOld}},model:{value:(_vm.form.currentPassword),callback:function ($$v) {_vm.$set(_vm.form, "currentPassword", $$v)},expression:"form.currentPassword"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"New Password","vid":"confirm","rules":"min:4|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 mb-1",attrs:{"autocomplete":"new-password","outlined":"","dense":"","color":"#F05326","label":"New Password","type":_vm.passwordShow.showNew ? 'text' : 'password',"append-icon":_vm.passwordShow.showNew ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"required":""},on:{"click:append":function($event){_vm.passwordShow.showNew = !_vm.passwordShow.showNew}},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Confirm New Password","rules":"min:4|max:20|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 mb-1",attrs:{"autocomplete":"new-password","outlined":"","dense":"","color":"#F05326","label":"Confirm New Password","type":_vm.passwordShow.showConfirm ? 'text' : 'password',"append-icon":_vm.passwordShow.showConfirm ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updatePassword.apply(null, arguments)},"click:append":function($event){_vm.passwordShow.showConfirm = !_vm.passwordShow.showConfirm}},model:{value:(_vm.form.verifyNewPassword),callback:function ($$v) {_vm.$set(_vm.form, "verifyNewPassword", $$v)},expression:"form.verifyNewPassword"}})]}}])})],1),_c('div',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"#F05326","outlined":"","dense":"","depressed":""},on:{"click":function($event){_vm.password = !_vm.password}}},[_vm._v(" "+_vm._s(!_vm.password ? 'Change Password' : 'Undo Change Password')+" ")])],1),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.name === 'xs' ? 'mt-2' : ''},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":_vm.save}},[_vm._v(" Save Change ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }